import { ReactNode } from 'react'
import {
  DehydratedState,
  HydrationBoundary,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query'

type Props = {
  children: ReactNode
  dehydratedState?: DehydratedState
}

const twentyFourHoursInMs = 1000 * 60 * 60 * 24
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      refetchOnMount: false,
      retry: false,
      staleTime: twentyFourHoursInMs,
    },
  },
})

export default function ReactQueryProvider({
  children,
  dehydratedState,
}: Props) {
  return (
    <QueryClientProvider client={queryClient}>
      <HydrationBoundary state={dehydratedState}>
        {children}
        {/*{process.env.NEXT_PUBLIC_APP_MODE !== 'production' && (*/}
        {/*  <ReactQueryDevtools initialIsOpen={true} />*/}
        {/*)}*/}
      </HydrationBoundary>
    </QueryClientProvider>
  )
}
