import { css } from '@emotion/react'

/**
 * 버튼
 */
export const BUTTON_TYPE = {
  FILL: 'FILL',
  OUTLINE: 'OUTLINE',
  TEXT: 'TEXT',
  TONAL: 'TONAL',
} as const
export type ButtonType = keyof typeof BUTTON_TYPE

export const BUTTON_COLOR = {
  PRIMARY: 'PRIMARY',
  WHITE: 'WHITE',
} as const
export type ButtonColor = keyof typeof BUTTON_COLOR

export const BUTTON_POSITION = {
  RIGHT: 'RIGHT',
  LEFT: 'LEFT',
}

export type ButtonPosition = keyof typeof BUTTON_POSITION

export const TAG_TYPE = {
  FILL: 'FILL',
  OUTLINE: 'OUTLINE',
} as const
export type TagType = keyof typeof TAG_TYPE

/**
 * 디바이스 사이즈
 */
export const size = {
  desktop: '1200px',
  tablet: '1024px',
  tabletSm: '768px',
  mobile: '430px',
} as const

export const MEDIA_QUERY_MAX_WIDTH = {
  DESKTOP: `(max-width: ${size.desktop})`,
  TABLET: `(max-width: ${size.tablet})`,
  TABLET_SM: `(max-width: ${size.tabletSm})`,
  MOBILE: `(max-width: ${size.mobile})`,
} as const

export const MEDIA_QUERY_MIN_WIDTH = {
  DESKTOP: `(min-width: ${size.desktop})`,
  TABLET: `(min-width: ${size.tablet})`,
  TABLET_SM: `(min-width: ${size.tabletSm})`,
  MOBILE: `(min-width: ${size.mobile})`,
} as const

/**
 * 그리드 레이아웃
 */
const gridLayout = {
  desktop: {
    frame: size.desktop,
    count: '12',
    width: 'auto',
    margin: '24px',
    gutter: '24px',
  },
  tablet: {
    frame: size.tablet,
    count: '12',
    width: 'auto',
    margin: '24px',
    gutter: '24px',
  },
  tabletSm: {
    frame: size.tabletSm,
    count: '12',
    width: 'auto',
    margin: '24px',
    gutter: '24px',
  },
  mobile: {
    frame: size.mobile,
    count: '6',
    width: 'auto',
    margin: '24px',
    gutter: '24px',
  },
} as const

/**
 * 색상
 */
export const colors = {
  brand: {
    primary: {
      default: '#000000',
      hover: '#141414',
      pressed: '#292929',
      disabled: 'rgba(0, 0, 0, 0.38)',
    },
    secondary: {
      default: '#2D2D2D',
      hover: '#3E3E3E',
      pressed: '#4F4F4F',
      disabled: 'rgba(45, 45, 45, 0.38)',
    },
    tertiary: {
      default: '#CDCDCD',
      hover: '#D1D1D1',
      pressed: '#D5D5D5',
      disabled: 'rgba(205, 205, 205, 0.38)',
    },
    white: {
      default: '#FFFFFF',
      hover: '#EBEBEB',
      pressed: '#D6D6D6',
      disabled: 'rgba(255, 255, 255, 0.38)',
    },
  },
  sub: {
    blue: {
      default: '#2065EC',
      hover: '#3271EE',
      pressed: '#447EEF',
      disabled: 'rgba(32, 101, 236, 0.38)',
    },
    orange: {
      default: '#FF5B15',
      hover: '#FF6828',
      pressed: '#FF753A',
      disabled: 'rgba(255, 91, 21, 0.38)',
    },
    green: {
      default: '#0FA858',
      hover: '#22AF65',
      pressed: '#35B673',
      disabled: 'rgba(15, 168, 88, 0.38)',
    },
    olive: {
      default: '#245A35',
      hover: '#366745',
      pressed: '#477455',
      disabled: 'rgba(36, 90, 53, 0.38)',
    },
    yellow: {
      default: '#FECF39',
      hover: '#FDD31D',
      pressed: '#FDD730',
      disabled: 'rgba(254, 207, 57, 0.38)',
    },
    perple: {
      default: '#885EFF',
      hover: '#926BFF',
      pressed: '#9B78FF',
      disabled: 'rgba(136, 94, 255, 0.38)',
    },
    royalblue: {
      default: '#5433FF',
      hover: '#6243FF',
      pressed: '#6F54FF',
      disabled: 'rgba(84, 51, 255, 0.38)',
    },
    gold: {
      default: '#D3B18D',
      hover: '#D7B796',
      pressed: '#DABD9F',
      disabled: 'rgba(211, 177, 141, 0.38)',
    },
    red: {
      default: '#CA2131',
      hover: '#CE3341',
      pressed: '#D24552',
      disabled: 'rgba(202, 33, 49, 0.38)',
    },
  },
  environment: {
    background: {
      default: '#FFFFFF',
      on: '#000000',
    },
  },
  state: {
    error: {
      default: '#F15746',
    },
    success: {
      default: '#00B507',
    },
  },
  gray: {
    gray0: '#000000',
    gray10: '#333333',
    gray20: '#3D3B3B',
    gray30: '#4B4B4B',
    gray40: '#666',
    gray50: '#7D7D7D',
    gray60: '#9F9F9F',
    gray70: '#BBBBBB',
    gray80: '#D2D2D2',
    gray90: '#E6E6E6',
    gray95: '#F4F4F4',
    gray99: '#FDFDFD',
    gray100: '#FFFFFF',
  },
  text: {
    high: {
      black: '#333333',
      white: '#FFFFFF',
    },
    medium: {
      black: 'rgba(51, 51, 51, 0.60)',
      white: 'rgba(255, 255, 255, 0.60)',
    },
    disabled: {
      black: 'rgba(51, 51, 51, 0.38)',
      white: 'rgba(255, 255, 255, 0.38)',
    },
  },
  icon: {
    active: {
      black: '#000000',
      white: '#FFF',
    },
    inactive: {
      black: 'rgba(0, 0, 0, 0.60)',
      white: 'rgba(255, 255, 255, 0.60)',
    },
    disabled: {
      black: 'rgba(0, 0, 0, 0.38)',
      white: 'rgba(255, 255, 255, 0.38)',
    },
  },
  sns: {
    naver: {
      bg: '#03C75A',
      text: '#FFFFFF',
    },
    kakao: {
      logo: '#000000',
      text: '#000000D9',
      bg: '#FEE500',
    },
    google: {
      bg: '#FFFFFF',
      text: '#000000',
    },
    apple: {
      logo: '#FFFFFF',
      bg: '#050708',
      text: '#FFFFFF',
    },
    facebook: {
      logo: '#FFFFFF',
      bg: '#1877F2',
      text: '#FFFFFF',
    },
  },
} as const

/**
 * 타이포그래피
 */
const typography = {
  h1: {
    bl: css`
      font-size: 24px;
      font-style: normal;
      font-weight: 900;
      line-height: 34px; /* 141.667% */
      letter-spacing: -0.4px;

      color: ${colors.gray.gray10};
    `,
    b: css`
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 34px; /* 141.667% */
      letter-spacing: -0.4px;

      color: ${colors.gray.gray10};
    `,
    m: css`
      font-size: 24px;
      font-style: normal;
      font-weight: 500;
      line-height: 34px; /* 141.667% */
      letter-spacing: -0.4px;

      color: ${colors.gray.gray10};
    `,
    r: css`
      font-size: 24px;
      font-style: normal;
      font-weight: 400;
      line-height: 34px; /* 141.667% */
      letter-spacing: -0.4px;

      color: ${colors.gray.gray10};
    `,
    l: css`
      font-size: 24px;
      font-style: normal;
      font-weight: 300;
      line-height: 34px; /* 141.667% */
      letter-spacing: -0.4px;
      color: ${colors.gray.gray10};
    `,
    el: css`
      font-size: 24px;
      font-style: normal;
      font-weight: 200;
      line-height: 34px; /* 141.667% */
      letter-spacing: -0.4px;
      color: ${colors.gray.gray10};
    `,
    t: css`
      font-size: 24px;
      font-style: normal;
      font-weight: 100;
      line-height: 34px; /* 141.667% */
      letter-spacing: -0.4px;
      color: ${colors.gray.gray10};
    `,
  },
  h2: {
    bl: css`
      font-size: 20px;
      font-style: normal;
      font-weight: 900;
      line-height: 28px; /* 140% */
      letter-spacing: -0.4px;
      color: ${colors.gray.gray10};
    `,
    b: css`
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 28px; /* 140% */
      letter-spacing: -0.4px;
      color: ${colors.gray.gray10};
    `,
    m: css`
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: 28px; /* 140% */
      letter-spacing: -0.4px;
      color: ${colors.gray.gray10};
    `,
    r: css`
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 28px; /* 140% */
      letter-spacing: -0.4px;
      color: ${colors.gray.gray10};
    `,
    l: css`
      font-size: 20px;
      font-style: normal;
      font-weight: 300;
      line-height: 28px; /* 140% */
      letter-spacing: -0.4px;
      color: ${colors.gray.gray10};
    `,
    el: css`
      font-size: 20px;
      font-style: normal;
      font-weight: 200;
      line-height: 28px; /* 140% */
      letter-spacing: -0.4px;
      color: ${colors.gray.gray10};
    `,
    t: css`
      font-size: 20px;
      font-style: normal;
      font-weight: 100;
      line-height: 28px; /* 140% */
      letter-spacing: -0.4px;
      color: ${colors.gray.gray10};
    `,
  },
  h3: {
    bl: css`
      font-size: 18px;
      font-style: normal;
      font-weight: 900;
      line-height: 26px; /* 144.444% */
      letter-spacing: -0.4px;
      color: ${colors.gray.gray10};
    `,
    b: css`
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: 26px; /* 144.444% */
      letter-spacing: -0.4px;
      color: ${colors.gray.gray10};
    `,
    m: css`
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 26px; /* 144.444% */
      letter-spacing: -0.4px;
      color: ${colors.gray.gray10};
    `,
    r: css`
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 26px; /* 144.444% */
      letter-spacing: -0.4px;
      color: ${colors.gray.gray10};
    `,
    l: css`
      font-size: 18px;
      font-style: normal;
      font-weight: 300;
      line-height: 26px; /* 144.444% */
      letter-spacing: -0.4px;
      color: ${colors.gray.gray10};
    `,
    el: css`
      font-size: 18px;
      font-style: normal;
      font-weight: 200;
      line-height: 26px; /* 144.444% */
      letter-spacing: -0.4px;
      color: ${colors.gray.gray10};
    `,
    t: css`
      font-size: 18px;
      font-style: normal;
      font-weight: 100;
      line-height: 26px; /* 144.444% */
      letter-spacing: -0.4px;
      color: ${colors.gray.gray10};
    `,
  },
  body1: {
    bl: css`
      font-size: 16px;
      font-style: normal;
      font-weight: 900;
      line-height: 22px; /* 137.5% */
      letter-spacing: -0.4px;
      color: ${colors.gray.gray10};
    `,
    b: css`
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 22px; /* 137.5% */
      letter-spacing: -0.4px;
      color: ${colors.gray.gray10};
    `,
    m: css`
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 22px; /* 137.5% */
      letter-spacing: -0.4px;
      color: ${colors.gray.gray10};
    `,
    r: css`
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px; /* 137.5% */
      letter-spacing: -0.4px;
      color: ${colors.gray.gray10};
    `,
    l: css`
      font-size: 16px;
      font-style: normal;
      font-weight: 300;
      line-height: 22px; /* 137.5% */
      letter-spacing: -0.4px;
      color: ${colors.gray.gray10};
    `,
    el: css`
      font-size: 16px;
      font-style: normal;
      font-weight: 200;
      line-height: 22px; /* 137.5% */
      letter-spacing: -0.4px;
      color: ${colors.gray.gray10};
    `,
    t: css`
      font-size: 16px;
      font-style: normal;
      font-weight: 100;
      line-height: 22px; /* 137.5% */
      letter-spacing: -0.4px;
      color: ${colors.gray.gray10};
    `,
  },
  body2: {
    bl: css`
      font-size: 14px;
      font-style: normal;
      font-weight: 900;
      line-height: 20px; /* 142.857% */
      letter-spacing: -0.4px;
      color: ${colors.gray.gray10};
    `,
    b: css`
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 20px; /* 142.857% */
      letter-spacing: -0.4px;
      color: ${colors.gray.gray10};
    `,
    m: css`
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px; /* 142.857% */
      letter-spacing: -0.4px;
      color: ${colors.gray.gray10};
    `,
    r: css`
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px; /* 142.857% */
      letter-spacing: -0.4px;
      color: ${colors.gray.gray10};
    `,
    l: css`
      font-size: 14px;
      font-style: normal;
      font-weight: 300;
      line-height: 20px; /* 142.857% */
      letter-spacing: -0.4px;
      color: ${colors.gray.gray10};
    `,
    el: css`
      font-size: 14px;
      font-style: normal;
      font-weight: 200;
      line-height: 20px; /* 142.857% */
      letter-spacing: -0.4px;
      color: ${colors.gray.gray10};
    `,
    t: css`
      font-size: 14px;
      font-style: normal;
      font-weight: 100;
      line-height: 20px; /* 142.857% */
      letter-spacing: -0.4px;
      color: ${colors.gray.gray10};
    `,
  },
  body3: {
    bl: css`
      font-size: 12px;
      font-style: normal;
      font-weight: 900;
      line-height: 18px; /* 150% */
      letter-spacing: -0.4px;
      color: ${colors.gray.gray10};
    `,
    b: css`
      font-size: 12px;
      font-style: normal;
      font-weight: 700;
      line-height: 18px; /* 150% */
      letter-spacing: -0.4px;
      color: ${colors.gray.gray10};
    `,
    m: css`
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 18px; /* 150% */
      letter-spacing: -0.4px;
      color: ${colors.gray.gray10};
    `,
    r: css`
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px; /* 150% */
      letter-spacing: -0.4px;
      color: ${colors.gray.gray10};
    `,
    l: css`
      font-size: 12px;
      font-style: normal;
      font-weight: 300;
      line-height: 18px; /* 150% */
      letter-spacing: -0.4px;
      color: ${colors.gray.gray10};
    `,
    el: css`
      font-size: 12px;
      font-style: normal;
      font-weight: 200;
      line-height: 18px; /* 150% */
      letter-spacing: -0.4px;
      color: ${colors.gray.gray10};
    `,
    t: css`
      font-size: 12px;
      font-style: normal;
      font-weight: 100;
      line-height: 18px; /* 150% */
      letter-spacing: -0.4px;
      color: ${colors.gray.gray10};
    `,
  },
  caption: {
    bl: css`
      font-size: 12px;
      font-style: normal;
      font-weight: 900;
      line-height: 16px; /* 133.333% */
      letter-spacing: -0.4px;
      color: ${colors.gray.gray10};
    `,
    b: css`
      font-size: 12px;
      font-style: normal;
      font-weight: 700;
      line-height: 16px; /* 133.333% */
      letter-spacing: -0.4px;
      color: ${colors.gray.gray10};
    `,
    m: css`
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 16px; /* 133.333% */
      letter-spacing: -0.4px;
      color: ${colors.gray.gray10};
    `,
    r: css`
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px; /* 133.333% */
      letter-spacing: -0.4px;
      color: ${colors.gray.gray10};
    `,
    l: css`
      font-size: 12px;
      font-style: normal;
      font-weight: 300;
      line-height: 16px; /* 133.333% */
      letter-spacing: -0.4px;
      color: ${colors.gray.gray10};
    `,
    el: css`
      font-size: 12px;
      font-style: normal;
      font-weight: 200;
      line-height: 16px; /* 133.333% */
      letter-spacing: -0.4px;
      color: ${colors.gray.gray10};
    `,
    t: css`
      font-size: 12px;
      font-style: normal;
      font-weight: 100;
      line-height: 16px; /* 133.333% */
      letter-spacing: -0.4px;
      color: ${colors.gray.gray10};
    `,
  },
  overline: {
    bl: css`
      font-size: 10px;
      font-style: normal;
      font-weight: 900;
      line-height: 14px; /* 140% */
      letter-spacing: -0.4px;
      color: ${colors.gray.gray10};
    `,
    b: css`
      font-size: 10px;
      font-style: normal;
      font-weight: 700;
      line-height: 14px; /* 140% */
      letter-spacing: -0.4px;
      color: ${colors.gray.gray10};
    `,
    m: css`
      font-size: 10px;
      font-style: normal;
      font-weight: 500;
      line-height: 14px; /* 140% */
      letter-spacing: -0.4px;
      color: ${colors.gray.gray10};
    `,
    r: css`
      font-size: 10px;
      font-style: normal;
      font-weight: 400;
      line-height: 14px; /* 140% */
      letter-spacing: -0.4px;
      color: ${colors.gray.gray10};
    `,
    l: css`
      font-size: 10px;
      font-style: normal;
      font-weight: 300;
      line-height: 14px; /* 140% */
      letter-spacing: -0.4px;
      color: ${colors.gray.gray10};
    `,
    el: css`
      font-size: 10px;
      font-style: normal;
      font-weight: 200;
      line-height: 14px; /* 140% */
      letter-spacing: -0.4px;
      color: ${colors.gray.gray10};
    `,
    t: css`
      font-size: 10px;
      font-style: normal;
      font-weight: 100;
      line-height: 14px; /* 140% */
      letter-spacing: -0.4px;
      color: ${colors.gray.gray10};
    `,
  },
} as const

/**
 * 미디어 쿼리
 */
const media = {
  desktop: `@media only screen and (max-width: ${size.desktop})`,
  tablet: `@media only screen and (max-width: ${size.tablet})`,
  tabletSm: `@media only screen and (max-width: ${size.tabletSm})`,
  mobile: `@media only screen and (max-width: ${size.mobile})`,
  onlyTablet: `@media (min-width: ${size.tabletSm}) and (max-width: ${size.tablet}) `,
  onlyTabletSm: `@media (min-width: ${size.mobile}) and (max-width: ${size.tabletSm}) `,
  onlyMobile: `@media (min-width: 0px) and (max-width: ${size.mobile})`,

  safariIos: `@supports (-webkit-text-size-adjust:none) and (not (-ms-accelerator:true)) and (not (-moz-appearance:none))`,
  safariNonIos: `_:-webkit-full-screen:not(:root:root), .safari_only`,
  safariWeb: `@media screen and (min-color-index:0) and(-webkit-min-device-pixel-ratio:0)`,
} as const

const animation = {
  imageRotate:
    'animation: rotate_image 65s linear infinite;\n  transform-origin: 50% 50%;',
}

export const theme = {
  gridLayout: gridLayout,
  colors: colors,
  typography: typography,
  media: media,
  size: size,
  animation: animation,
} as const

export type Theme = typeof theme
