import { ReactNode, useEffect, useMemo } from 'react'
import { createGlobalState } from 'react-use'

import { useSession } from '@/hooks/auth/use-session'
import { channelService } from '@/utils/channel-talk'

const CHANNEL_TALK_PLUGIN_KEY = process.env
  .NEXT_PUBLIC_CHANNEL_TALK_PLUGIN_KEY as string

const useChannelTalkCount = createGlobalState(0)

interface ChannelTalkControllerProps {
  children?: ReactNode
}

/**
 * `app.tsx`에 삽입하여 채널톡을 표시, 제어하는 역할을 함\
 * 페이지, 레이아웃, 컴포넌트 등에서 `ChannelTalk`이 삽입되면 채널톡을 표시함
 * @see ChannelTalk
 */
export const ChannelTalkController = ({
  children,
}: ChannelTalkControllerProps) => {
  const [channelTalkCount] = useChannelTalkCount()
  const isShowChannelTalk = useMemo(
    () => channelTalkCount > 0,
    [channelTalkCount],
  )
  const { data: session } = useSession()
  // const memberId = useMemo(() => session?.user?.id, [session?.user?.id])
  const memberId = ''

  useEffect(() => {
    if (isShowChannelTalk) {
      if (!memberId) {
        channelService.boot({
          pluginKey: CHANNEL_TALK_PLUGIN_KEY,
        })
      } else {
        channelService.boot({
          pluginKey: CHANNEL_TALK_PLUGIN_KEY,
          memberId,
        })
      }
    } else {
      channelService.shutdown()
    }
  }, [isShowChannelTalk, memberId])

  return <>{children}</>
}

interface ChannelTalkProps {
  children?: ReactNode
}

/**
 * 채널톡을 표시해야 하는 페이지, 컴포넌트 내에 삽입하면 `ChannelTalkController`에서 채널톡을 표시함
 * @see ChannelTalkController
 */
export const ChannelTalk = ({ children }: ChannelTalkProps) => {
  const [, setChannelTalkCount] = useChannelTalkCount()

  useEffect(() => {
    setChannelTalkCount((count) => count + 1)

    return () => {
      setChannelTalkCount((count) => count - 1)
    }
  }, [setChannelTalkCount])

  return <>{children}</>
}
