import { FC, ReactNode, useEffect, useState } from 'react'
import { Toaster } from 'react-hot-toast'
import localFont from 'next/font/local'
import Router from 'next/router'
import nProgress from 'nprogress'
import smoothScroll from 'smoothscroll-polyfill'
import { EmotionCache } from '@emotion/cache'
import { CacheProvider, Global } from '@emotion/react'
import { ThemeProvider } from '@emotion/react'

import GlobalStyles from '@shared/lib//styles/global-styles'
import {
  Theme as KacThemeType,
  theme as kacTheme,
} from '@shared/lib//styles/theme'

import 'nprogress/nprogress.css'

declare module '@emotion/react' {
  export interface Theme extends KacThemeType {}
}

if (typeof window !== 'undefined') {
  smoothScroll.polyfill()
}

nProgress.configure({ showSpinner: false })

Router.events.on('routeChangeStart', nProgress.start)
Router.events.on('routeChangeError', nProgress.done)
Router.events.on('routeChangeComplete', nProgress.done)

const pretendard = localFont({
  src: '../assets/fonts/PretendardVariable.woff2',
  display: 'swap',
  weight: '45 920',
})

const Fonts: FC = () => {
  return (
    <style
      jsx
      global
    >
      {`
        :root {
          --pretendard: ${pretendard.style.fontFamily};
        }

        body {
          font-family: ${pretendard.style.fontFamily} !important;
        }
      `}
    </style>
  )
}

const VhRootVariableSetter: FC = () => {
  const [vh, setVh] = useState<number>()

  useEffect(() => {
    const handleResize = () => {
      setVh(window.innerHeight / 100)
    }

    window.addEventListener('resize', handleResize)
    handleResize()

    return () => window.removeEventListener('resize', handleResize)
  }, [])

  if (!vh) {
    return null
  }

  return (
    <style
      jsx
      global
    >
      {`
        :root {
          --vh: ${vh}px;
        }
      `}
    </style>
  )
}

interface StyleProps {
  emotionCache: EmotionCache
  children: ReactNode
}

export const StyleProvider = ({ children, emotionCache }: StyleProps) => {
  return (
    <>
      <CacheProvider value={emotionCache}>
        <ThemeProvider
          theme={{
            ...kacTheme,
          }}
        >
          {children}
          <Global styles={GlobalStyles} />
          <Fonts />
          <VhRootVariableSetter />
          <Toaster />
        </ThemeProvider>
      </CacheProvider>
    </>
  )
}
