import { useEffect } from 'react'
import { useRouter } from 'next/router'

// import {
//   ,
//   NAVER_TRACKING_ID,
// } from '@/constants/analytics'

export const NaverTrackerHead = () => {
  return (
    <>
      <script
        async
        src="//wcs.naver.net/wcslog.js"
      ></script>
    </>
  )
}

export const NaverTrackerBody = () => {
  return (
    <>
      {/*<script*/}
      {/*  id="naver-tracker-run"*/}
      {/*  dangerouslySetInnerHTML={{*/}
      {/*    __html: `*/}
      {/*      if(!wcs_add) var wcs_add = {};*/}
      {/*      wcs_add["wa"] = "${NAVER_TRACKING_ID}";*/}

      {/*      if (!_nasa) var _nasa={};*/}
      {/*      */}
      {/*      if(window.wcs) {*/}
      {/*        wcs.inflow("${}}");*/}
      {/*        wcs_do(_nasa);*/}
      {/*      }*/}
      {/*    `,*/}
      {/*  }}*/}
      {/*></script>*/}
    </>
  )
}

/**
 * 네이버 트래커용 window 타입 정의
 */
declare global {
  type NaSa = {
    [key: string]: string | undefined
  }

  interface Window {
    wcs_add: {
      [key: string]: string
    }
    wcs_do: (nasa?: NaSa) => void
    wcs: {
      cnv: (type: string, value: string) => string
      inflow: (domain: string) => void
    }
    _nasa: NaSa
  }
}

export const NaverTrackerByRouter = () => {
  const router = useRouter()

  useEffect(() => {
    // if (typeof window === 'undefined') {
    //   return
    // }
    //
    // if (!window.wcs_add) {
    //   window.wcs_add = {}
    // }
    //
    // window.wcs_add['wa'] = NAVER_TRACKING_ID
    //
    // if (!window['_nasa']) window['_nasa'] = {}
    //
    // const _nasa: {
    //   cnv?: string
    //   [key: string]: string | undefined
    // } = {
    //   ...window['_nasa'],
    // }
    //
    // /**
    //  * 회원가입 완료 페이지에서만 회원가입 전환 스크립트 실행
    //  */
    // // if (router.asPath.startsWith('/')) {
    // //   if (window.wcs) {
    // //     _nasa['cnv'] = window.wcs.cnv('2', '0')
    // //   }
    // // }
    //
    // if (window.wcs) {
    //   window.wcs.inflow()
    //   window.wcs_do(_nasa)
    // }
  }, [router.asPath])

  return null
}
