import { useMemo } from 'react'
import { useSession as useNextAuthSession } from 'next-auth/react'

import { Session } from '@/types/auth'

export const useSession = () => {
  const { data, status } = useNextAuthSession()

  const isAuth = useMemo(() => status === 'authenticated', [status])

  return {
    data: <Session | null>data,
    status,
    isAuth,
  }
}
