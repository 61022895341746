import { DefaultSeoProps } from 'next-seo'
export const TITLE = ''
export const DESCRIPTION = ''

export const THUMBNAIL_URL = process.env.NEXT_PUBLIC_HOST + '/og-thumbnail.png'

const config: DefaultSeoProps = {
  openGraph: {
    type: 'website',
    locale: 'ko_KR',
    url: process.env.NEXT_PUBLIC_HOST,
    siteName: '케아클',
    title: TITLE,
    description: DESCRIPTION,
    images: [
      {
        url: THUMBNAIL_URL,
      },
    ],
  },
  twitter: {
    site: process.env.NEXT_PUBLIC_HOST,
    cardType: 'summary',
  },
  title: TITLE,
  description: DESCRIPTION,
  canonical: process.env.NEXT_PUBLIC_HOST,
}

export default config
