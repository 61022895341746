import { FC } from 'react'

import {
  NaverTrackerByRouter,
  NaverTrackerHead,
} from '@/components/common/features/analytics-tracker/naver-tracker'

import { GaTrackerBody } from './ga-tracker'
import { GtmTrackerBody, GtmTrackerHead } from './gtm-tracker'

const isProduction = process.env.NEXT_PUBLIC_MODE === 'production'

export const AnalyticsTrackerHead: FC = () => {
  if (!isProduction) {
    return null
  }

  return (
    <>
      <NaverTrackerHead />
      <GtmTrackerHead />
    </>
  )
}

export const AnalyticsTrackerBody: FC = () => {
  if (!isProduction) {
    return null
  }

  return (
    <>
      <GaTrackerBody />
      <GtmTrackerBody />
    </>
  )
}

export const AnalyticsTrackerByRouter: FC = () => {
  if (!isProduction) {
    return null
  }

  return (
    <>
      <NaverTrackerByRouter />
    </>
  )
}
